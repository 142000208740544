<template>
    <div class="viewContainer" v-loading="loading">
        <!-- 内容 -->
        <div class="topTitle">{{content.title}}</div>

        <hr  class="hr">

        <div  class="content"  v-html="content.content"></div>
    </div>
</template>
<script>
import { talentconcept, campus, joinus, } from '@/services/joinUs.js'
export default {
  name: "talentconcept",
  components:{
  },
  data() {
    return {
        routeName:'',
        loading:false,
        content:{},
    };
  },
  computed: {
  },
  created() {
  },
  destroyed() {
  },
  watch: {
  },
  mounted() {
  },
  activated(){
    console.log('this.$route---',this.$route)
    if(this.$route.name == 'JoinUs' || this.$route.name == 'Talentconcept'){//页面参数
        this.getTalentconcept()
    }else if(this.$route.name =='Campus'){
        this.getCampus()
    }else if(this.$route.name =='Joinus'){
        this.getJoinus()
    }
  },
  methods:{

    //人才理念
    getTalentconcept(){
        this.content = {}
        this.loading = true;
        talentconcept().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //校园招聘
    getCampus(){
        this.content = {}
        this.loading = true;
        campus().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //社会招聘
    getJoinus(){
        this.content = {}
        this.loading = true;
        joinus().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
  }
};
</script>
<style scoped lang="less">
 .viewContainer{
    width: 100%;
    text-align: left;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;

    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content{
        font-size: 15px !important;
    }
    .content /deep/span{
        font-size: 15px !important;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
    .content /deep/h4{
        font-size: 16px !important;
        font-weight: 700;
        color: #002c85 !important;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
    .content /deep/li{
        margin-bottom: 8px;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
    .content /deep/strong{
        color: rgb(0, 176, 240);
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
 }
 
@media screen and (max-width: 370px) {
   
}
</style>