import { getAction, postAction } from '@/utils/http.js'//引入封装的get post方法

//人才理念
const talentconcept = (params)=>getAction("/api/official/website/about_us/talent_concept",params);
//校园招聘
const campus = (params)=>getAction("/api/official/website/about_us/recruitment",params);
//社会招聘
const joinus = (params)=>getAction("/api/official/website/about_us/join_us",params);
export {
    talentconcept,
    campus,
    joinus,
}